<template>
  <div>
    <b-card no-body class="mb-2">
      <!-- Table Header -->
      <div class="m-2">
        <b-row>
          <!-- Left -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
<!--            <table-options
                :paging-size="table.paging.size"
                :paging-sizes="table.paging.options.sizes"
                :selectable-fields="selectableFields"
                :sortable-fields="sortableFields"
                :sort-by="table.sorting.by"
                :sort-desc="table.sorting.desc"
                :selectable-enabled="state.selectable.enabled"
                :selected-count="table.selected.items.length"
                @page-size="onPageSize"
                @sort-by="onSortBy"
                @sort-order="onSortOrder"
                @select-all="selectAllRows"
                @unselect-all="clearSelected"
                @delete-selected="deleteSelectedItems"
            />-->
          </b-col>

          <!-- Right -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="input-group-merge">
                <b-form-input
                    v-model="table.search.value"
                    :debounce="250"
                    autocomplete="off"
                    placeholder="Search..."
                    class="search-product"
                />
                <b-input-group-append v-if="table.search.value" is-text>
                  <feather-icon icon="XIcon" class="text-muted" @click="table.search.value = ''" />
                </b-input-group-append>
                <b-input-group-append>
                  <b-dropdown right :text="searchType" size="sm" variant="transparent" toggle-class="search-dropdown">
                    <b-dropdown-item v-for="type in table.search.options.types" :key="type.value" @click="table.search.type = type.value">
                      {{ type.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="text-muted" />
                </b-input-group-append>
              </b-input-group>

              <!-- Table Options -->
              <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" dropright>
                <template #button-content>
                  <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" class="align-middle text-body"></font-awesome-icon>
                </template>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table -->
      <div id="table-container">
        <b-table
            id="table" ref="table"
            class="position-relative" table-class="table-flush" thead-class="thead-light" tbody-class="border-0" tbody-tr-class="tbody-tr-border"
            responsive
            :fields="table.fields"
            :items="table.items"
            :busy="table.loading"
            :per-page="table.paging.size"
            :current-page="table.paging.page"
            show-empty
            empty-text="No matching records found"
            :filter="table.search"
            :filter-debounce="200"
            :filter-function="onFilter"
            @filtered="onFiltered">

          <!-- Table Busy Slot -->
          <template #table-busy>
            <overlay-loading :items="[
                  { state: table.paging.loading, desc: 'Loading Paging Details', loaded: table.paging.tokens.length},
                  { state: table.loading, desc: 'Loading Applications' },
                  //{ state: state.loading, desc: 'Rendering Template'},
                  ]">
            </overlay-loading>
          </template>
        </b-table>
      </div>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span v-if="table.items.length > 0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.of }} entries</span>
            <span v-else class="text-muted">Showing 0 of {{ pagination.of }} entries</span>
          </b-col>

          <!-- Cache -->
<!--          <b-col v-if="cacheTime" class="d-flex align-items-center justify-content-center">
            <div class="text-muted font-small-3">
              <div v-if="cacheExpired">Cache Expired</div>
              <last-modified v-else :date="cacheTime" text="Cached "></last-modified>
            </div>
          </b-col>-->

          <!-- Pagination -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
                v-model="table.paging.page"
                :per-page="table.paging.size"
                :total-rows="table.paging.total"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="getPage($event)">

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <debug>
      {{ table.items }}
    </debug>

    <debug>
      {{ table.paging.tokens.map(item => ({page: item.page, loaded: item.loaded })) }}
    </debug>
  </div>
</template>

<script>
import {API, graphqlOperation} from 'aws-amplify';
import {listApplications, listApplicationsPaged} from '@/graphql/queries/test';
import OverlayLoading from '@/components/OverlayLoading.vue';
import _ from 'lodash';
import Fuse from 'fuse.js';
import LastModified from '@/components/LastModified.vue';

export default {
  name: 'Test',
  components: {
    LastModified,
    OverlayLoading},
  data() {
    return {
      table: {
        fields: [
          {
            key: 'festival.name',
            label: 'Festival',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'form.name',
            label: 'Form',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'instrument.name',
            label: 'Instrument',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'teacher.name',
            subKeys: ['teacher.name.first', 'teacher.name.last'],
            label: 'Teacher',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'student.name',
            subKeys: ['student.name.first', 'student.name.last'],
            label: 'Student',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'student.school.name.legal',
            label: 'School',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'createdAt',
            label: 'Created',
            sortable: true,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'updatedAt',
            label: 'Last Modified',
            sortable: true,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'row-options',
            label: '',
            sortable: false,
            filterable: false,
            visible: true,
            tdClass: ['align-middle', 'table-row-options']
          },
        ],
        items: [],
        loading: true,
        paging: {
          page: 1,
          size: 100,
          total: 0,
          tokens: [],
          loading: true,
          options: {
            sizes: [10, 25, 50, 100]
          },
        },
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              {
                text: 'Fuzzy',
                value: ''
              },
              {
                text: 'Exact',
                value: '='
              },
              {
                text: 'Includes',
                value: '\''
              },
              {
                text: 'Starts With',
                value: '^'
              },
            ]
          },
        }
      },
    }
  },
  computed: {
    searchType() {
      return this.table.search.options.types.find(type => type.value === this.table.search.type).text
    },
    pagination() {
      const to = this.table.paging.size * (this.table.paging.page)
      return {
        from: this.table.paging.size * (this.table.paging.page - 1) + (this.table.items.length > 0 ? 1 : 0),
        to: to < this.table.paging.total ? to : this.table.paging.total,
        of: this.table.paging.total,
      }
    },
  },
  async created() {
    await this.listApplications()
  },
  methods: {
    async listApplications(page, nextToken, pagedApplications) {
      const currentPage = page + 1 || 1
      const applications = pagedApplications || []
      const input = { limit: this.table.paging.size, nextToken: nextToken }

      const response = await API.graphql(graphqlOperation(listApplicationsPaged, input));
      applications.push(...response.data.listApplications.items);

      this.table.paging.tokens.push({ page: currentPage, token: nextToken, loaded: false })

      if(response.data.listApplications.nextToken) {
        await this.listApplications(currentPage, response.data.listApplications.nextToken, applications)
      }
      else {
        this.table.items = applications
        this.table.paging.total = applications.length
        this.table.paging.loading = false
        await this.getPage(1)
      }
    },

    async getPage(page) {
      const p = this.table.paging.tokens.find(item => item.page === page)
      if(!p.loaded) {
        this.table.loading = true
        const applications = []
        const input = { limit: this.table.paging.size, nextToken: p.token }
        const response = await API.graphql(graphqlOperation(listApplications, input));
        applications.push(...response.data.listApplications.items);

        const start = (page - 1) * this.table.paging.size

        this.table.items.splice(start, applications.length, ...applications)
        this.table.loading = false
        p.loaded = true
      }
    },

    async onFilter(item, filter) {
      /** If the search value is blank and every filter value is blank, return the row. **/
      if(_.isEmpty(filter.value)) {
        return true
      }

      /** Otherwise - Lets Search!  **/
      const keys = []
      this.table.fields.filter(field => field.filterable).forEach(field => {
        if(field.subKeys) {
          //for fields like name that may display both the first and last name we need to use the sub keys instead of the key.
          field.subKeys.forEach(subKey => keys.push(subKey))
        }
        else {
          keys.push(field.key)
        }
      })

      const fuse = new Fuse([item], {
        useExtendedSearch: true,
        threshold: 0.3,
        keys: keys
      })

      /** Build the Search Query  **/
      const query = { $and: [ ] }

      /** Build the Optional Or Clauses  **/
      if(filter.value) {
        const or = []
        keys.forEach(key => {
          or.push({[key]: `${filter.type}${filter.value}` })
        })
        query.$and.push({ $or: or }) //Push our $or clauses into the $and clause
      }

      /** If the object we passed into the fuse constructor meets the search criteria, the row should be returned **/
      return fuse.search(query).length > 0
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.table.paging.total = filteredItems.length
      //this.table.paging.page = 1
    },


  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

@media print {
  .table-action{
    display:none;
  }
}

.table-actions {
  width: 33px;
  text-align: center;
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}

.tbody-tr-border {
  border-bottom: 1px solid #e9ecef;
}

.tbody-tr-border:last-child { border-bottom: none; }

$search-dropdown-color: #b9b9c3;
$search-dropdown-border-color: #d8d6de;
$search-dropdown-border-color-focus: #212969;
$chevron-color: black;
$chevron-down: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='#{$chevron-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E";

.search-dropdown {
  background: transparent;
  color: $search-dropdown-color;
  border-top: 1px solid $search-dropdown-border-color;
  border-bottom: 1px solid $search-dropdown-border-color;
}

.search-dropdown.dropdown-toggle::after {
  background-image: url($chevron-down)!important;
}

.input-group .search-dropdown{
  border-color: $search-dropdown-border-color;
}
.input-group:focus-within .search-dropdown{
  border-color: $search-dropdown-border-color-focus;
}

.input-group:focus-visible .search-dropdown{
  border-color: $search-dropdown-border-color-focus;
}
</style>
