export const listApplications = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                festival {
                    id
                    name
                }
                form {
                    id
                    name
                }
                instrument {
                    id
                    name
                }
                student {
                    id
                    name {
                        first
                        last
                    }
                    school {
                        name {
                            legal
                        }
                    }
                }
                teacher {
                    id
                    name {
                        first
                        last
                    }
                }
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const listApplicationsPaged = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
            }
            nextToken
        }
    }
`;
